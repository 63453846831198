import { useState, useContext, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import UserContext from '../../context/UserContext';

const Navbar = () => {
    const [signedIn, setSignedIn] = useState(true);
    const [feedsDropdown, setFeedsDropdown] = useState(false);
    const [dashDropdown, setDashDropdown] = useState(false);

    const { user, pending, signOut } = useContext(UserContext);

    // This function will get the logged in status of the user 
    // when the page first loads and anytime there is a change 
    // to the status of pending or user
    useEffect(() => {
        if (typeof user === "object" && !Array.isArray(user) && user !== null && pending !== "pending") {
            setSignedIn(true)
        } else {
            setSignedIn(false)
        }
    }, [pending, user])

    // Function to logout the current user
    const logout = async () => {
        const res = await signOut();
        setSignedIn(false);

        if (res.data.detail[0].msg) {
            toast.error(res.data.detail[0].msg);
        } else {
            toast.error(res.data.detail);
        }

        closeMenus();
    }

    const showDashboardMenu = () => {
        setDashDropdown(true);
        setFeedsDropdown(false);
    }

    const showFeedsMenu = () => {
        setFeedsDropdown(true);
        setDashDropdown(false);
    }

    const closeMenus = () => {
        setDashDropdown(false);
        setFeedsDropdown(false);
    }

    const DashboardMenu = () => {
        return (
            <header className='nav-menu-header' onMouseOver={showDashboardMenu} onMouseOut={closeMenus}>
                <div className="nav-menu">
                    <div className='nav-menu-section'>
                        <Link to="/" className='nav-menu-link nav-menu-dashboard' onClick={() => closeMenus()}>
                            <h2>Global Cyber Intelligence Dashboard</h2>
                            <img src="/pictures/cyberdash-pic.png" className='nav-menu-img' />
                            <p className="nav-menu-text">Forescout Vedere Labs created the Global Cyber Intelligence Dashboard to communicate its data, research and analysis to the broader cybersecurity community. It leverages 39 billion data points collected from millions of deployed IT, IoT, IoMT and OT devices, as well as robust network data stored in our proprietary data lake. The dashboard is a unique source of information about vulnerabilities and the global state of cyber risk.</p>
                        </Link>
                    </div>
                    <div className='nav-menu-section'>
                        <Link to="/attacks-dashboard" className='nav-menu-link nav-menu-dashboard' onClick={() => closeMenus()}>
                            <h2>Attacks Dashboard</h2>
                            <img src="/pictures/attack-locations.png" className='nav-menu-img' />
                            <p className="nav-menu-text">The Attacks Dashboard features regularly updated data about where most attacks originate, what attack techniques are used most frequently in enterprise and OT environments, what vulnerabilities are most exploited and other relevant information.</p>
                        </Link>
                    </div>
                    <div className='nav-menu-section'>
                        <Link to="/threat-actors-dashboard" className='nav-menu-link nav-menu-dashboard' onClick={() => closeMenus()}>
                            <h2>Threat Actor Dashboard</h2>
                            <img src='/pictures/threat-actor.png' className='nav-menu-img' />
                            <p className="nav-menu-text">The Threat Actors Dashboard offers daily insights into the most active threat actors, detailing their origins and identifying what countries and industries they target the most.</p>
                        </Link>
                    </div>
                    <div className='nav-menu-section'>
                        <Link to="/vulnerabilities" className="nav-menu-link nav-menu-dashboard" onClick={() => closeMenus()}>
                            <h2>Vulnerability Disclosure Dashboard</h2>
                            <img src='/pictures/vulndash.png' className='nav-menu-img' />
                            <p className="nav-menu-text">The Vulnerability Disclosure Dashboard is a collection of all Vedere Labs found CVEs, with information on each CVE such as product, vendor, and CVSS score.</p>
                        </Link>
                    </div>
                </div>
            </header>
        )
    }

    const FeedMenu = () => {
        return (
            <header className='nav-menu-header' onMouseOver={showFeedsMenu} onMouseOut={closeMenus}>
                <div className="nav-menu">

                    {signedIn ? (
                        <>
                            <div className='nav-menu-section-small'>
                                <Link to="/profile" className='nav-menu-link' onClick={() => closeMenus()}>
                                    <h2>Profile</h2>
                                    <img src='/pictures/Profile.png' className='nav-menu-img' />
                                </Link>
                            </div>
                            <div className='nav-menu-section-small'>
                                <Link to="/documentation" className='nav-menu-link' onClick={() => closeMenus()}>
                                    <h2>Documentation</h2>
                                    <img src='/pictures/Documentation.png' className='nav-menu-img' />
                                </Link>
                            </div>
                            <div className='nav-menu-section-small'>
                                <Link to="/" onClick={() => logout()} className='nav-menu-link'>
                                    <h2>Sign Out</h2>
                                </Link>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className='nav-menu-section-small'>
                                <Link to="/signin" className='nav-menu-link' onClick={() => closeMenus()}>
                                    <h2>Sign In</h2>
                                    <img src='/pictures/SignIn.png' className='nav-menu-img' />
                                </Link>
                            </div>
                            <div className='nav-menu-section-small'>
                                <Link to="/register" className='nav-menu-link' onClick={() => closeMenus()}>
                                    <h2>Register</h2>
                                    <img src='/pictures/Register.png' className='nav-menu-img' />
                                </Link>
                            </div>
                        </>
                    )}
                </div>
            </header>
        )
    }

    return (
        <>
            <div className='navbar'>
                <img src='icons/forescout-research-vedere-labs-white.svg' className="navbar-logo" />
                <div className="navbar-links">
                    <h3 onMouseOver={showDashboardMenu}>Dashboards</h3>
                    <h3 onMouseOver={showFeedsMenu}>Threat Feed Service</h3>
                    <h3 onMouseOver={closeMenus}>
                        <Link to="/copilot" className="navbar-link">
                            Copilot Integration
                        </Link>
                    </h3>
                </div>
            </div>
            {dashDropdown && (<DashboardMenu />)}
            {feedsDropdown && (<FeedMenu />)}
        </>
    )
}

export default Navbar