import {
    ResponsiveContainer,
    AreaChart,
    XAxis,
    YAxis,
    CartesianGrid,
    Area,
    Tooltip,
    Legend,
    Label
} from 'recharts';
import Info from './Info';
import { COLORS } from '../../utils/colors';
import { makePercent } from '../../utils/helpers';

function formatDate(rawDate) {
    let textDate = new Date(rawDate);
    return (textDate.getMonth() + 1) + '/' + textDate.getDate();
}

function combineData(data) {
    let fullData = {};

    data.forEach((datum) => {
        datum.timeseries.forEach((item) => {
            let tempPair = {};
            tempPair[datum.key] = item.value;
            if (!(item.key_as_string in fullData)) fullData[item.key_as_string] = {};
            Object.assign(fullData[item.key_as_string], tempPair);
        })
    });

    return (
        Object.keys(fullData).map((key) => (
            {
                name: key,
                date: formatDate(key),
                ...fullData[key]
            }
        ))
    )
}

function AreaWrapper(props) {
    const { data, title, tooltip, x_label, y_label } = props.data
    const areaData = combineData(data);

    return (
        <div className="AreaWrapper">
            <Info description={tooltip} />
            <header>
                <h2>{title.toUpperCase()}</h2>
            </header>
            <ResponsiveContainer width="100%" height="100%">
                <AreaChart data={areaData}>
                    <defs>
                        <linearGradient id="colorLow" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor={COLORS[0]} stopOpacity={0.8} />
                            <stop offset="95%" stopColor={COLORS[0]} stopOpacity={0} />
                        </linearGradient>
                        <linearGradient id="colorMedium" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor={COLORS[12]} stopOpacity={0.8} />
                            <stop offset="95%" stopColor={COLORS[12]} stopOpacity={0} />
                        </linearGradient>
                        <linearGradient id="colorHigh" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor={COLORS[8]} stopOpacity={0.8} />
                            <stop offset="95%" stopColor={COLORS[8]} stopOpacity={0} />
                        </linearGradient>
                        <linearGradient id="colorCritical" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor={COLORS[3]} stopOpacity={0.8} />
                            <stop offset="95%" stopColor={COLORS[3]} stopOpacity={0} />
                        </linearGradient>
                    </defs>
                    <XAxis
                        dataKey="date"
                        interval={3}
                        axisLine={{
                            stroke: "#cccccc"
                        }}
                        tickLine={{
                            stroke: "#cccccc"
                        }}
                        tick={{ fontSize: ".75rem" }}
                        angle={-45}
                        textAnchor="end"
                        height={80}
                    >
                        <Label
                            value={x_label}
                            position="insideBottom"
                            offset={20}
                        />
                    </XAxis>
                    <YAxis
                        tick={{
                            fontSize: ".75rem",
                        }}
                        axisLine={{
                            stroke: "#cccccc"
                        }}
                        tickLine={{
                            stroke: "#cccccc"
                        }}
                        tickFormatter={(value) => makePercent(value, 0)}
                        scale="sqrt"
                    >
                        <Label
                            value={y_label}
                            angle={-90}
                            position="insideLeft"
                            offset={10}
                            style={{
                                textAnchor: "middle"
                            }}
                        />
                    </YAxis>
                    <CartesianGrid
                        vertical={false}
                    />
                    <Area
                        type="monotone"
                        dataKey="Low Risk (0.1-4)"
                        dot={false}
                        strokeWidth={1}
                        stroke={COLORS[0]}
                        fillOpacity={1}
                        fill="url(#colorLow)"
                    />
                    <Area
                        type="monotone"
                        dataKey="Medium Risk (4-7)"
                        dot={false}
                        strokeWidth={1}
                        stroke={COLORS[7]}
                        fillOpacity={1}
                        fill="url(#colorMedium)"
                    />
                    <Area
                        type="monotone"
                        dataKey="High Risk (7-9)"
                        dot={false}
                        strokeWidth={1}
                        stroke={COLORS[8]}
                        fillOpacity={1}
                        fill="url(#colorHigh)"
                    />
                    <Area
                        type="monotone"
                        dataKey="Critical Risk (9-10)"
                        dot={false}
                        strokeWidth={1}
                        stroke={COLORS[3]}
                        fillOpacity={1}
                        fill="url(#colorCritical)"
                    />
                    <Tooltip
                        cursor={{ fill: 'transparent' }}
                        formatter={(value) => makePercent(value, 1)}
                    />
                    <Legend
                        align="left"
                        iconType="circle"
                        formatter={(value) => <span className="legend-value">{value}</span>}
                        wrapperStyle={{
                            left: 22
                        }}
                    />
                </AreaChart>
            </ResponsiveContainer>
        </div>
    );
}

export default AreaWrapper;