import { toast } from "react-toastify";
import { FaCopy, FaExpandAlt, FaTimes } from "react-icons/fa";

const VLCVEs = () => {
    const showExample = (show) => {
        document.getElementById(show).classList.remove("hidden");
    };

    const hideExample = (hide) => {
        document.getElementById(hide).classList.add("hidden");
    };

    const copyExample = () => {
        navigator.clipboard.writeText(
            "import requests\r\nurl = 'https://api.feeds.vederelabs.com/feedservice/api/cves/vloriginal'\r\nheaders = {\r\n\t'api-key' : '{your_api_key}'\r\n}\r\nresponse = requests.get(url, headers=headers)\r\nprint(response.text)"
        );
        toast.success("API request has been copied!", {
            position: toast.POSITION.TOP_LEFT,
        });
    };

    return (
        <div className="column">
            <div className="desc-section">
                <h2>Vedere Labs Original CVEs</h2>
                <p>
                    This API query call will enable you to get the list of CVEs originally discovered by Vedere Labs as well as all the software related to it. This call will return a dictionary that has the Vedere Labs' project names as key, and a list of STIX Bundle Objects as value. Each STIX Bundle contains STIX Vulnerability Objects to represent the CVE, as well as STIX Software Observables to represent the CPE related to it , if they exist.
                </p>
            </div>
            <div className="example">
                <div className="codeHeader">
                    <p>API Request (Python)</p>
                    <FaCopy className="icon" onClick={() => copyExample()} />
                </div>
                <div className="code">
                    <p className="codeLine">
                        <span className="highlight">import</span> requests
                    </p>
                    <p className="codeLine">
                        url <span className="highlight">= "</span>
                        <span className="quote">
                            https://api.feeds.vederelabs.com/feedservice/api/cves/vloriginal
                        </span>
                        <span className="highlight">"</span>
                    </p>
                    <p className="codeLine">
                        headers <span className="highlight">= </span> &#123;
                    </p>
                    <p className="codeLine tab">
                        <span className="highlight">'api-key' </span>:
                        <span className="highlight"> '&#123;your_api_key&#125;'</span>
                    </p>
                    <p className="codeLine">&#125;</p>
                    <p className="codeLine">
                        response <span className="highlight">=</span> requests.get(url,
                        headers=headers)
                    </p>
                    <p className="codeLine">
                        <span className="secHighlight">print</span>(response.text)
                    </p>
                </div>
            </div>
            <div className="responses">
                <div className="response" onClick={() => showExample("code1")}>
                    <div className="greenCircle"></div>
                    <p>HTTP code 200 - Successful Response</p>
                    <FaExpandAlt className="expand" />
                </div>
                <div className="code hidden" id="code1">
                    <FaTimes className="close" onClick={() => hideExample("code1")} />
                    <p>&#123;</p>
                    <p className="tab">"NUMBER: JACK": [</p>
                    <p className="tab2">&#123;</p>
                    <p className="tab3">"type": "bundle",</p>
                    <p className="tab3">
                        "id": "bundle--9923b3b5-0dd3-40e4-a327-3cbc2c93fcdd",
                    </p>
                    <p className="tab3">"objects": [</p>
                    <p className="tab4">&#123;</p>
                    <p className="tab5">"type": "vulnerability",</p>
                    <p className="tab5">"spec_version": "2.1",</p>
                    <p className="tab5">
                        "id": "vulnerability--beaa47c2-ad5c-4196-b96f-f6603f98c800",
                    </p>
                    <p className="tab5">"created": "2022-07-22T13:04:12.555706z",</p>
                    <p className="tab5">"modified": "2022-07-22T13:04:12.555706z",</p>
                    <p className="tab5">"name": "CVE-2020-27213",</p>
                    <p className="tab5">
                        "description": "Nut/Net software relies on highly predictable source
                        values and has consistent increments when generating initial
                        sequence numbers (ISN), which may allow an attacker to spoof or
                        disrupt TCP connections."
                    </p>
                    <p className="tab4">&#125;,</p>
                    <p className="tab4">&#123;</p>
                    <p className="tab5">"type": "software",</p>
                    <p className="tab5">"spec_version": "2.1",</p>
                    <p className="tab5">
                        "id": "software--30532971-e279-591c-8cbf-417220a14533",
                    </p>
                    <p className="tab5">name": "Nut/OS",</p>
                    <p className="tab5">
                        "cpe": "cpe:2.3:o:ethernut:nut\\/os:*:*:*:*:*:*:*:*",
                    </p>
                    <p className="tab5">"vendor": "Ethernut",</p>
                    <p className="tab5">"version": "&#60;&#61;5.1"</p>
                    <p className="tab4">&#125;</p>
                    <p className="tab3">]</p>
                    <p className="tab2">&#125;,</p>
                    <p className="tab2">&#123;</p>
                    <p className="tab3">"type": "bundle",</p>
                    <p className="tab3">
                        "id": "bundle--d0ec3075-517f-4406-b953-bb6d8bb204e7",
                    </p>
                    <p className="tab3">"objects": [</p>
                    <p className="tab4">&#123;</p>
                    <p className="tab5">"type": "vulnerability",</p>
                    <p className="tab5">"spec_version": "2.1",</p>
                    <p className="tab5">
                        "id": "vulnerability--0353bdee-d605-4169-a8b9-6a9c69461d64",
                    </p>
                    <p className="tab5">"created": "2022-07-22T13:04:12.585291Z",</p>
                    <p className="tab5">"modified": "2022-07-22T13:04:12.585291Z",</p>
                    <p className="tab5">"name": "CVE-2020-27634",</p>
                    <p className="tab5">
                        "description": "uIP, Contiki-OS, and Contiki-NG software is
                        initialized with a consistent value and has consistent increments
                        when generating initial sequence numbers (ISN), which may allow an
                        attacker to spoof or disrupt TCP connections"
                    </p>
                    <p className="tab4">&#125;,</p>
                    <p className="tab4">&#123;</p>
                    <p className="tab5">"type": "software",</p>
                    <p className="tab5">"spec_version": "2.1",</p>
                    <p className="tab5">
                        "id": "software--6d4f45af-7871-5c26-a808-2f264601f183",
                    </p>
                    <p className="tab5">"name": "uIP",</p>
                    <p className="tab5">
                        "cpe": "cpe:2.3:a:uip_project:uip:1.0:*:*:*:*:*:*:*",
                    </p>
                    <p className="tab5">"vendor": "uIP Project\nContiki",</p>
                    <p className="tab5">"version": "&#60;&#61;1.0 and &#60;&#61;3.0"</p>
                    <p className="tab4">&#125;,</p>
                    <p className="tab4">&#123;</p>
                    <p className="tab5">"type": "software",</p>
                    <p className="tab5">"spec_version": "2.1",</p>
                    <p className="tab5">
                        "id": "software--8f37c3e2-6f07-5b9d-98a1-74539e18235c",
                    </p>
                    <p className="tab5">"name": "Contiki",</p>
                    <p className="tab5">
                        "cpe": "cpe:2.3:o:contiki-os:contiki:*:*:*:*:*:*:*:*",
                    </p>
                    <p className="tab5">"vendor": "uIP Project\nContiki",</p>
                    <p className="tab5">"version": "&#60;&#61;1.0 and &#60;&#61;3.0"</p>
                    <p className="tab4">&#125;</p>
                    <p className="tab3">]</p>
                    <p className="tab2">&#125;</p>
                    <p className="tab">]</p>
                    <p>&#125;</p>
                </div>

                <div className="response" onClick={() => showExample("code2")}>
                    <div className="redCircle"></div>
                    <p>HTTP code 401 - Not authenticated</p>
                    <FaExpandAlt className="expand" />
                </div>
                <div className="code hidden" id="code2">
                    <FaTimes className="close" onClick={() => hideExample("code2")} />
                    <p>&#123;"detail": "Not Authenticated"&#125;</p>
                </div>

                <div className="response" onClick={() => showExample("code3")}>
                    <div className="redCircle"></div>
                    <p>
                        HTTP code 403 - User has not permissions to access the requested
                        resource
                    </p>
                    <FaExpandAlt className="expand" />
                </div>
                <div className="code hidden" id="code3">
                    <FaTimes className="close" onClick={() => hideExample("code3")} />
                    <p>
                        &#123;"detail": "User has not permissions to access the requested
                        resource"&#125;
                    </p>
                </div>

                <div className="response" onClick={() => showExample("code4")}>
                    <div className="redCircle"></div>
                    <p>HTTP code 404 - Resource not found</p>
                    <FaExpandAlt className="expand" />
                </div>
                <div className="code hidden" id="code4">
                    <FaTimes className="close" onClick={() => hideExample("code4")} />
                    <p>&#123;"detail": "Resource not found"&#125;</p>
                </div>

                <div className="response" onClick={() => showExample("code5")}>
                    <div className="redCircle"></div>
                    <p>HTTP code 422 - Validation error</p>
                    <FaExpandAlt className="expand" />
                </div>
                <div className="code hidden" id="code5">
                    <FaTimes className="close" onClick={() => hideExample("code5")} />
                    <p>&#123;"detail": "Validation error"&#125;</p>
                </div>

                <div className="response" onClick={() => showExample("code6")}>
                    <div className="redCircle"></div>
                    <p>HTTP code 500 - Error processing the request</p>
                    <FaExpandAlt className="expand" />
                </div>
                <div className="code hidden" id="code6">
                    <FaTimes className="close" onClick={() => hideExample("code6")} />
                    <p>&#123;"detail": "Not Found"&#125; </p>
                </div>
            </div>
            <p className="field">Response Fields</p>
            <ul className="variables">
                <p className="subfield">STIX Bundle</p>
                <li className="italic">
                    <span className="bold">type:</span>type of the STIX Object, it must be
                    "bundle" in this case
                </li>
                <li className="italic">
                    <span className="bold">id:</span>the ID of the Bundle
                </li>
                <li className="italic">
                    <span className="bold">objects:</span>a list of STIX Objects
                </li>
            </ul>
            <ul className="variables">
                <p className="subfield">STIX Vulnerability Object</p>
                <li className="italic">
                    <span className="bold">type:</span>type of the STIX Object. In this
                    case, it must be "vulnerability"
                </li>
                <li className="italic">
                    <span className="bold">spec_version:</span>the version of the STIX
                    specification used to represent this object. The value of this
                    property must be 2.1
                </li>
                <li className="italic">
                    <span className="bold">id:</span>the ID of the Vulnerability
                </li>
                <li className="italic">
                    <span className="bold">created:</span>the time at which the STIX
                    Vulnerability Object was originally created
                </li>
                <li className="italic">
                    <span className="bold">modified:</span>the time that this particular
                    version of the STIX Object was last modified
                </li>
                <li className="italic">
                    <span className="bold">name:</span>the name used to identify the
                    Vulnerability
                </li>
                <li className="italic">
                    <span className="bold">description:</span>a description that provides
                    more details and context about the Vulnerability
                </li>
                <li className="italic">
                    <span className="bold">x_CVSSv3.x:</span>CVVSv3 base score (custom
                    property)
                </li>
                <li className="italic">
                    <span className="bold">x_CVSSv3.x_vector:</span>vector of base score
                    metrics (custom property)
                </li>
            </ul>
            <ul className="variables">
                <p className="subfield">STIX Software Observable</p>
                <li className="italic">
                    <span className="bold">type:</span>type of the STIX Object. In this
                    case, it must be "software"
                </li>
                <li className="italic">
                    <span className="bold">spec_version:</span>the version of the STIX
                    specification used to represent this object. The value of this
                    property must be 2.1
                </li>
                <li className="italic">
                    <span className="bold">id:</span>the ID of the software
                </li>
                <li className="italic">
                    <span className="bold">name:</span>the name used to identify the
                    software
                </li>
                <li className="italic">
                    <span className="bold">cpe:</span>specifies the Common Platform
                    Enumeration (CPE) entry for the software, if available. The value for
                    this property must be a CPE v2.3 entry from the official NVD CPE
                    Dictionary
                </li>
                <li className="italic">
                    <span className="bold">vendor:</span>specifies the name of the vendor
                    of the software
                </li>

                <li className="italic">
                    <span className="bold">version:</span>specifies the version of the
                    software
                </li>
            </ul>

        </div>
    );
};

export default VLCVEs;