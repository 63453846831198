const Explanations = () => {
  return (
    <div className="explanations">
      <div className="explanation">
        <div className="extitle">
          <div className="whyIcon">
            <img
              src="icons/spyglass.svg"
              alt="spyglass"
              className="explanationIcon"
            />
          </div>
          <p className="exHeader">Why Vedere Labs Threat Feeds Service?</p>
        </div>
        <p className="body">
          The threat landscape is continuously evolving. The Vedere Labs team of
          cyber security experts monitors threat actors, threat campaigns,
          trends and incidents by analyzing a great variety of internal and
          external resources. Information gathered during these activities is
          shared with the community via the Threat Feed Service
        </p>
      </div>
      <div className="explanation">
        <div className="extitle">
          <div className="whyIcon">
            <img
              src="icons/fingerprint.svg"
              alt="fingerprint"
              className="explanationIcon"
            />
          </div>
          <p className="exHeader">What to expect?</p>
        </div>
        <p className="body">
          After registration, the user will be able to query the Threat Feed API
          to access IoC (indicators of compropmise) in STIX (Structured Threat
          Information Expression) format. IoC will include blacklisted IPs,
          URLs, domain names, file hashes and more
        </p>
      </div>
      <div className="explanation">
        <div className="extitle">
          <div className="whyIcon">
            <img
              src="icons/network_database.svg"
              alt="network database"
              className="explanationIcon"
            />
          </div>
          <p className="exHeader">What is unique?</p>
        </div>
        <p className="body">
          Vedere Labs Threat Feeds bring to the community a unique set of threat
          information coming from original vulnerability research activities
          (e.g., OT:ICEFALL, Project Memoria), manual and automatic analysis of
          malware samples collected on the ground or observed over our own
          Adversary Engagement Platform as well as malicious activites observed
          over the networks of Forescout customers
        </p>
      </div>
      <div className="explanation">
        <div className="extitle">
          <div className="whyIcon">
            <img
              src="icons/vedere-labs-logo-white-simple.svg"
              alt="vedere labs logo"
              className="explanationIcon"
            />
          </div>
          <p className="exHeader">Download the eyeExtend Connect App</p>
        </div>
        <p className="body">
          The eyeExtend Connect App allows to integrate Forescout Vedere Labs Threat Intelligence Feeds into Forescout solutions. <a className="explanations_link" href="https://marketplace.forescout.com/en-US/apps/423521/eyeextend-connect-app-for-vedere-labs/overview?utm_source=forescout.vederelabs.com" target="_blank">Download it here!</a>
        </p>
      </div>
    </div>
  );
};

export default Explanations;
