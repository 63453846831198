import { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { FaCopy, FaEye, FaInfo } from "react-icons/fa";
import UserContext from "../context/UserContext";
import PasswordReq from "../components/base/PasswordReq";
import Spinner from "../components/base/Spinner";
import CountryInput from "../components/ThreatFeeds/CountryInput";
import USStateInput from "../components/ThreatFeeds/USStateInput";
import CanadaStateInput from "../components/ThreatFeeds/CanadaStateInput"

const Profile = () => {
  const { user, updateUser, showPassword, setUser, loading } = useContext(UserContext);
  const [formData, setFormData] = useState({
    currentPassword: '',
    newPassword: '',
    newPassword2: '',
    job_title: user['job_title'] !== null ? user['job_title'] : '',
    country: user['country'] !== null ? user['country'] : '',
    state: user['state'] !== null ? user['state'] : '',
    is_marketing_accepted: user['is_marketing_accepted'] !== null ? user['is_marketing_accepted'] : "false"
  })

  const { currentPassword, newPassword, newPassword2, job_title, country, state, is_marketing_accepted } = formData;

  // Function to ensure that the passwords match
  const confirmPassword = () => {
    if (newPassword !== newPassword2) {
      return false;
    } else {
      return true;
    }
  };

  // Check that the password meets password requirements
  const checkStrength = () => {
    if (newPassword.length > 7) {
      var pattern = new RegExp(
        "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[-+_!@#$%^&*.,?]).+$"
      );

      if (!pattern.test(newPassword)) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const checkNewFields = () => {
    if (job_title === "" || country === "") {
      toast.error("Please fill out job title, and country fields below then click update preferences");

      return false
    } else {
      return true
    }
  }

  // Function to copy users api key to clipboard
  const copy = () => {
    navigator.clipboard.writeText(user["api_key"]);
    toast.success("API key copied to clipboard!", {
      position: toast.POSITION.TOP_LEFT,
      onOpen: () => {
        let ses = JSON.parse(sessionStorage.getItem("user"));
        sessionStorage.removeItem("user[api_key]");

        delete ses["api_key"];

        sessionStorage.setItem("user", JSON.stringify(ses));

        setUser(ses);
      },
    });
  };

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }))
  }

  // Function to call function to call API to update the password
  const submitNewPassword = async (e) => {
    e.preventDefault();

    const confirmedPassword = confirmPassword();

    // Add check for right amount of characters as well and wrap below if statement in another if statement(for different toast errors)
    const passwordStrength = checkStrength();
    const missingFields = checkNewFields();

    if (passwordStrength) {
      // If the passwords match
      if (confirmedPassword) {
        // Create user object to send to API call
        if (missingFields) {

          const userObj = {
            name: user["name"],
            last_name: user["last_name"],
            company: user["company"],
            email: user["email"],
            job_title: user['job_title'],
            country: user['country'],
            state: user['state'],
            current_password: currentPassword,
            password: newPassword,
            is_marketing_accepted: user['is_marketing_accepted']
          };

          // Call function to call API
          const res = await updateUser(userObj);

          if (res.status === 200) {
            toast.success("Password successfully updated!", {
              position: toast.POSITION.TOP_LEFT,
            });
          } else {
            if (res.data.detail[0].msg) {
              toast.error(res.data.detail[0].msg, {
                position: toast.POSITION.TOP_LEFT
              });
            } else {
              toast.error(res.data.detail, {
                position: toast.POSITION.TOP_LEFT
              });
            }
          }
        }
      } else {
        toast.error("Passwords must match");
      }
    } else {
      toast.error(
        "Passwords must be atleast 8 characters and contain a capitol letter, a number and a special character."
      );
    }
  };

  const updateMarketing = async (e) => {
    e.preventDefault();

    const missingFields = checkNewFields();

    if (missingFields) {

      if (is_marketing_accepted === "true") {
        setFormData((prevState) => ({
          ...prevState,
          is_marketing_accepted: true
        }))
      } else if (is_marketing_accepted === "false") {
        setFormData((prevState) => ({
          ...prevState,
          is_marketing_accepted: false
        }))
      }

      const data = {
        name: user['name'],
        last_name: user['last_name'],
        company: user['company'],
        email: user['email'],
        job_title: user['job_title'] ? user['job_title'] : job_title,
        country: user['country'] ? user['country'] : country,
        state: user['state'] ? user['state'] : state,
        is_marketing_accepted: is_marketing_accepted
      }

      const res = await updateUser(data);

      if (res.status === 200 && is_marketing_accepted === "true") {
        toast.success("Successfully subscribed to marketing!", {
          position: toast.POSITION.TOP_LEFT,
        });
      } else if (res.status === 200 && is_marketing_accepted === "false") {
        toast.success("Successfully unsubscribed from marketing!", {
          position: toast.POSITION.TOP_LEFT,
        });
      } else {
        if (res.data.detail[0].msg) {
          toast.error(res.data.detail[0].msg, {
            position: toast.POSITION.TOP_LEFT
          });
        } else {
          toast.error(res.data.detail, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      }
    }
  }

  // Function to call the function that calls the API to get a new API key
  const getApiKey = async () => {
    // Creating user object for the API
    const userObj = {
      name: user["name"],
      last_name: user["last_name"],
      company: user["company"],
      email: user["email"],
      job_title: user['job_title'],
      country: user['country'],
      state: user['state'],
      api_token_request: true,
      is_marketing_accepted: user['is_marketing_accepted']
    };

    const missingFields = checkNewFields();

    if (missingFields) {
      // Call function to call the API
      const res = await updateUser(userObj);

      if (res.data) {
        // If the API returns an error
        if (res.data.detail[0]) {
          toast.error(res.data.detail[0].msg, {
            position: toast.POSITION.TOP_LEFT
          });
        } else {
          toast.error(res.data.detail, {
            position: toast.POSITION.TOP_LEFT
          });
        }
      }
    }
  };

  const showPasswordRequirements = () => {
    const el = document.getElementById("passReq");

    el.classList.toggle("hidden")
  }

  return (
    <main>
      <div className="feedservice-section">
        <h2 className="feedservice-title">Threat Feed Service</h2>
        <p className="feedservice-desc">The Threat Feed Service allows our key partners and stakeholders to consume the threat intelligence generated by Forescout Vedere Labs in a machine-readable way. The feeds provide a list of daily updated indicators of compromise including bad IPs, bad DNS, bad file hashes and URLS. It also provides a list of known exploited vulnerabilities (VL-KEV) observed via our AEE.</p>
      </div>
      <div className="profile-area">
        <div className="profile-section">
          <div className="profile-small-section">
            <h2 className="profile-title">Thank You!</h2>
            <p className="profile-text">We greatly appreciate your confidence
              , {user['name']} {user['last_name']}
              , in our Threat Feed Service. If you have any questions and/or problems please email us at <a href="mailto:vederelabs@forescout.com">vederelabs@forescout.com</a></p>
          </div>
          <div className="profile-small-section">
            <p className="profile-text">View documentation on how to use the Threat Feed Service API</p>
            <Link to="/documentation" className="profile-btn">Documentation Page</Link>
          </div>
        </div>
        <div className="profile-section">
          <h2 className="profile-title">Manage Profile</h2>
          <div className="profile-section-small">
            <p>If you need an API key, or have forgotten your API key click below to receive a new one.</p>
            {user["api_key"] && (
              <div className="apiKeyContainer">
                <input
                  className="apiInput"
                  name="apiKey"
                  value={user["api_key"]}
                  type="text"
                  disabled
                />
                <FaCopy className="apiCopy" onClick={() => copy()} />
              </div>
            )}
            <button className="profile-btn" onClick={() => getApiKey()}>
              Request new API key
            </button>
          </div>
          <div className="profile-section-small">
            <p>If you need to change your password, fill out form below. If you forget your password click reset password link on sign in page.</p>
            <div className="fieldContainer">
              <input
                className="input passInput passQry"
                name="currentPassword"
                type="password"
                value={currentPassword}
                placeholder="Current Password*"
                required
                onChange={onChange}
              />
              <FaEye className="eye" onClick={() => showPassword()} />
            </div>
            <div className="fieldContainer">
              <input
                className="input passQry"
                name="newPassword"
                type="password"
                value={newPassword}
                placeholder="New Password*"
                required
                onChange={onChange}
              />
              <div className="info-icon-container" onClick={() => showPasswordRequirements()}>
                <FaInfo className="info-icon" />
              </div>
              <PasswordReq password={newPassword} />
            </div>
            <div className="fieldContainer">
              <input
                className="input passQry"
                name="newPassword2"
                type="password"
                value={newPassword2}
                placeholder="Confirm New Password*"
                required
                onChange={onChange}
              />
            </div>
            <button
              type="submit"
              className="profile-btn passwordBtn"
              onClick={(e) => submitNewPassword(e)}
            >
              Change Password
            </button>
          </div>
          <div className="profile-section-small">
            <p>Subscribe/Unsubscribe to our updates</p>
            <p>Subscribe (Unsubscribe) here to receive (or stop receiving) the latest research updates, marketing materials and newsletter from Forescout directly to your inbox.</p>
            <div>
              <input type="radio" name="is_marketing_accepted" checked={is_marketing_accepted === "true" || is_marketing_accepted === true} value="true" onChange={onChange} />
              <label htmlFor="subscribe">Subscribe</label>
            </div>
            {user['job_title'] == "" || user['country'] == null && (
              <div className="fieldContainer">
                <input
                  type="text"
                  name="job_title"
                  value={job_title}
                  className="input"
                  required
                  onChange={onChange}
                  placeholder="Job Title*"
                />
              </div>
            )}
            {user['country'] == '' || user['country'] == null && (
              <CountryInput country={country} onChange={onChange} />
            )}
            {user['state'] == '' || user['country'] == null && (
              country == "US" ? (
                <USStateInput state={state} onChange={onChange} />
              ) : country == "CA" && (
                <CanadaStateInput state={state} onChange={onChange} />
              )
            )}
            <div>
              <input type="radio" name="is_marketing_accepted" checked={is_marketing_accepted === "false" || is_marketing_accepted === false} value="false" onChange={onChange} />
              <label htmlFor="unsubscribe">Unsubscribe</label>
            </div>
            <button
              type="submit"
              className="profile-btn passwordBtn"
              onClick={(e) => updateMarketing(e)}
            >
              Update Preferences
            </button>
          </div>
        </div>
      </div>

      {loading && <Spinner />}
    </main >
  );
};

export default Profile;
