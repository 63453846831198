import { useState, useContext, useRef } from "react";
import { FaEye, FaInfo } from "react-icons/fa";
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
import UserContext from "../../context/UserContext";
import PasswordReq from "../base/PasswordReq";
import CountryInput from "./CountryInput";
import USStateInput from "./USStateInput";
import CanadaStateInput from "./CanadaStateInput"

const Register = ({ setPending }) => {
  const [formData, setFormData] = useState({
    name: '',
    last_name: '',
    company: '',
    job_title: '',
    country: '',
    state: '',
    email: '',
    email2: '',
    password: '',
    password2: '',
    is_marketing_accepted: false,
  })

  const { name, last_name, company, job_title, country, state, email, email2, password, password2, is_marketing_accepted } = formData;

  const { createUser, showPassword } = useContext(UserContext);
  const recaptchaRef = useRef();

  // Check that both passwords match
  const checkPasswords = () => {
    if (password === password2) {
      return true
    } else {
      return false
    }
  };

  const checkEmails = () => {
    if (email === email2) {
      return true
    } else {
      return false
    }
  }

  // Check that the password meets password requirements
  const checkStrength = (e) => {
    if (password.length > 7) {
      var pattern = new RegExp(
        "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[-+_!@#$%^&*.,?]).+$"
      );

      if (!pattern.test(password)) {
        return false
      } else {
        return true
      }
    } else {
      return false
    }
  };

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }))
  }

  const marketingChange = () => {
    setFormData((prevState) => ({
      ...prevState,
      is_marketing_accepted: !is_marketing_accepted
    }))
  }

  const showPasswordRequirements = () => {
    const el = document.getElementById("passReq");

    el.classList.toggle("hidden")
  }

  // Submit the user registration
  const onSubmit = async (e) => {
    e.preventDefault();

    if (checkEmails() && checkPasswords() && checkStrength()) {
      // Create user object
      let data = {
        name,
        last_name,
        company,
        job_title,
        country,
        state,
        email,
        password,
        is_privacy_accepted: true,
        is_marketing_accepted,
        captcha_token: recaptchaRef.current.getValue(),
      };

      // function to call API /users
      const res = await createUser(data);

      if (res.status !== 200) {
        // Display error message if the API call fails
        if (res.response.data.detail[0].msg) {
          toast.error(res.response.data.detail[0].msg);
        } else {
          toast.error(res.data.detail);
        }
      } else {
        setPending(true)
      }
    } else {
      // Reset passwords
      setFormData((prevState) => ({
        ...prevState,
        password: '',
        password2: ''
      }))
      // Display error message
      toast.error("Check that your emails match, your passwords match and your passwords meet the minimum requirements.");
    }
  };

  return (
    <div className="form-box">
      <p className="header">Register</p>
      <form onSubmit={onSubmit} className="form">
        <div className="fieldContainer">
          <input
            type="text"
            name="name"
            value={name}
            className="input"
            placeholder="First Name*"
            required
            onChange={onChange}
          />
        </div>
        <div className="fieldContainer">
          <input
            type="text"
            name="last_name"
            value={last_name}
            className="input"
            placeholder="Last Name*"
            required
            onChange={onChange}
          />
        </div>
        <div className="fieldContainer">
          <input
            type="text"
            name="company"
            value={company}
            className="input"
            placeholder="Company*"
            required
            onChange={onChange}
          />
        </div>
        <div className="fieldContainer">
          <input
            type="text"
            name="job_title"
            value={job_title}
            className="input"
            required
            onChange={onChange}
            placeholder="Job Title*"
          />
        </div>
        <CountryInput country={country} onChange={onChange} />
        {country == "US" ? (
          <USStateInput state={state} onChange={onChange} />
        ) : country == "CA" && (
          <CanadaStateInput state={state} onChange={onChange} />
        )}
        <div className="fieldContainer">
          <input
            type="email"
            name="email"
            value={email}
            className="input"
            placeholder="Email*"
            required
            onChange={onChange}
          />
        </div>
        <div className="fieldContainer">
          <input
            type="email"
            name="email2"
            value={email2}
            className="input"
            placeholder="Confirm Email*"
            required
            onChange={onChange}
          />

        </div>
        <div className="fieldContainer">
          <input
            type="password"
            name="password"
            value={password}
            className="input passQry"
            placeholder="Password*"
            required
            onChange={onChange}
          />
          <div className="info-icon-container" onClick={() => showPasswordRequirements()}>
            <FaInfo className="info-icon" />
          </div>
        </div>
        <PasswordReq password={password} />
        <div className="fieldContainer">
          <input
            type="password"
            name="password2"
            value={password2}
            className="input passQry passInput"
            placeholder="Confirm Password*"
            required
            onChange={onChange}
          />
          <FaEye className="eye" onClick={() => showPassword()} />
        </div>
        <div>
          <input type="checkbox" name="marketo" value={is_marketing_accepted} onChange={marketingChange} />
          <label className="boxLabel">Subscribe here to receive the latest research updates, marketing materials and newsletter from Forescout directly to your inbox.</label>
        </div>
        <div>
          <input type="checkbox" name="privacy" required />
          <label className="boxLabel">
            Agree to our{" "}
            <a
              href="https://www.forescout.com/privacy-policy/"
              target="_blank"
              rel="noreferrer"
              className="link"
            >
              Privacy Policy
            </a>
          </label>
        </div>
        <div>
          <input type="checkbox" name="privacy" required />
          <label className="boxLabel">
            Agree to our{" "}
            <a
              href="/acceptableuse"
              target="_blank"
              rel="noreferrer"
              className="link"
            >
              {" "}
              Acceptable Use Policy
            </a>
          </label>
        </div>
        <div>
          <input type="checkbox" name="privacy" required />
          <label className="boxLabel">
            Agree to our{" "}
            <a
              href="https://www.forescout.com/privacy-policy/cookie-notice/"
              target="_blank"
              rel="noreferrer"
              className="link"
            >
              Cookie Policy
            </a>
          </label>
        </div>
        <p className="cookie">
          A cookie is a small piece of data (text file) that a website – when
          visited by a user – asks your browser to store on your device in order
          to remember information about you, such as your language preference or
          login information. Those cookies are set by us and called first-party
          cookies. We also use third-party cookies – which are cookies from a
          domain different than the domain of the website you are visiting – for
          our advertising and marketing efforts. Please accept these cookies to
          gain access to this service
        </p>
        <div className="captcha">
          <ReCAPTCHA
            sitekey="6LcRO14gAAAAAOfhW4b67omi-EttRiGgmjTN72lY"
            ref={recaptchaRef}
          />
        </div>
        <button className="submitBtn">Register</button>
      </form>
    </div>
  );
};

export default Register;
